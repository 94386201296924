var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { PsBaseService } from './psbase.service';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
var NotificationService = /** @class */ (function (_super) {
    __extends(NotificationService, _super);
    function NotificationService(http, injector) {
        return _super.call(this, http, environment.apiDomain.notificationEndpoint + "/Notifications", injector) || this;
    }
    NotificationService.prototype.search = function (type, time) {
        var _this = this;
        var url = this.svUrl + "/" + type + "/" + time;
        return this.http.get(url)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    NotificationService.prototype.getFavourite = function (id, type, time) {
        var _this = this;
        var url = this.svUrl + "/Favourite/" + type + "/" + time + "/" + id;
        return this.http.get(url)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    NotificationService.prototype.unRead = function (type) {
        var _this = this;
        var url = this.svUrl + "/UnRead/" + type;
        return this.http.get(url)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    NotificationService.prototype.getById = function (id) {
        var _this = this;
        var url = this.svUrl + "/Read/" + id;
        return this.http.get(url)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    NotificationService.prototype.readAll = function () {
        var _this = this;
        var url = this.svUrl + "/ReadAll";
        return this.http.get(url)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    return NotificationService;
}(PsBaseService));
export { NotificationService };
