import {
    Component,
    OnInit,
    HostListener,
    ViewChild,
    Output,
    EventEmitter
} from "@angular/core";
import { NotificationService } from "../../services/notification.service";
import { SignalRService } from "../../services/signalr.service";
import { Router } from "@angular/router";
import { CustomRouterService } from "../../services/custom-router.service";
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: "li[ps-app-notification]",
    templateUrl: "./app-notification.component.html",
    styleUrls: ["./app-notification.component.css"],
    providers: [SignalRService]
})
export class PsAppNotificationComponent implements OnInit {
    maxNotification = 10;
    totalUnRead = 0;
    data = [];
    elViewNotificationNavInside: HTMLElement;

    url = "";
    urldetail = "";
    notifications = [];

    userId: number;
    currentItem: any;

    @ViewChild("notificationdetail") notificationDetail: any;

    @Output("openMenu") openMenu: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _signalRService: SignalRService,
        private _userService: UserService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _customRouterService: CustomRouterService
    ) { }

    ngOnInit() {
        this.userId = this._userService.getBasicUserInfo().userId;
        // this._signalRService.start(
        //     environment.signalrConfig.topic.notificationCreated,
        //     this.userId.toString(),
        //     this.notifyTrigger.bind(this)
        // );

        this.refreshNotification();
    }

    notifyTrigger(data: any) {
        this.notifications.unshift(data);

        if (this.notifications.length > this.maxNotification) {
            this.notifications.pop();
        }

        this.totalUnRead++;
    }

    refreshNotification() {
        // this._notificationService.getsByUserId(this.userId)
        //     .then(result => {
        //         this.notifications = result.data;
        //         this.totalUnRead = result.totalRecord;
        //     });
    }

    readAll(event) {
        event.preventDefault();
        event.stopPropagation();

        // this._notificationService.readAllByUserId(this.userId).then(rs => {
        //     this.notifications.forEach(element => {
        //         if (element.read != null) {
        //             element.read.push({ userId: this.userId });
        //         } else {
        //             element.read = [{ userId: this.userId }];
        //         }
        //     });
        // });

        this.totalUnRead = 0;
    }

    readById(data: any) {
        // this._notificationService.readById(data.id, this.userId).then(rs => {
        //     if (data.read != null) {
        //         data.read.push({ userId: this.userId });
        //     } else {
        //         data.read = [{ userId: this.userId }];
        //     }
        // });
    }

    getClassRead(item: any): string {
        let classRead = "un-read";
        if (item.read != null) {
            item.read.forEach(x => {
                if (x.userId.toString() === this.userId.toString()) {
                    classRead = "";
                }
            });
        }
        return classRead;
    }

    isReaded(item: any): boolean {
        if (item.read != null) {
            item.read.forEach(x => {
                if (x.userId.toString() === this.userId.toString()) {
                    return true;
                }
            });
        }
        return false;
    }

    goToLink(item: any) {
        // if (!this.isReaded(item)) {
        //     this.readById(item);
        //     this.totalUnRead--;
        // }

        const link = item.message.link;
        let rawLink = "";
        let queryString = {};
        let hash = "";

        if (link.indexOf("?") > -1) {
            const linkArr = link.split("?");
            rawLink = linkArr[0];

            if (linkArr[0].indexOf("#") > -1) {
                const linkArrHash = linkArr[0].split("#");

                queryString = linkArrHash[0];
                hash = linkArrHash[1];
            } else {
                queryString = linkArr[0];
            }
        } else {
            if (link.indexOf("#") > -1) {
                const linkArr = link.split("#");

                rawLink = linkArr[0];
                hash = linkArr[1];
            } else {
                rawLink = link;
            }
        }

        this._router.navigate([rawLink], {
            queryParams: queryString,
            fragment: hash
        });
    }

    headerClick(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    openMenuEvent($event) {
        this.openMenu.next($event);
    }
}
