
    <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
        <li [ngClass]="[
                isActive(i, child) ? 'activated' : '',
                isActive(i, child) && level == 0 ? 'active-menuitem': '',
                isNumber(level) ? 'menuitem-level-' + level : '',
                child.items ? 'has-submenu' : '',
                child.routerLink && child.routerLink !== '#' ? 'allow-click' : '',
                (level === 2) && (pinnedMenuIds.includes(child.id)) ? 'pinned' : '',
                1 > 0 ? 'menu-id-' + child.id : ''
            ]"
            [class]="child.badgeStyleClass" *ngIf="!(child.visible === false)"
            [hidden]="level > 2 || child.trangThai !== 1" [authorize]="child.phanQuyen"
                                [isNavigationPermission]="true"
                                [permissionType]="permissionType">

            <i class="fa fa-thumb-tack pinned-icon"
               title="{{pinnedMenuIds.includes(child.id) ? 'Bỏ ghim' : 'Ghim'}}"
               *ngIf="level === 2" (click)="togglePin(child.id)"></i>
            <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink"
               *ngIf="!child.routerLink || child.routerLink === '#'"
               [attr.tabindex]     = "!visible ? '-1' : null" [attr.target] = "child.target">
                <div class="decoration" *ngIf="level === 2">
                    <i class="fa fa-cube decorated-icon"></i>
                </div>
                <i class="fa fa-caret-right menuitem-toggle-icon" *ngIf="level === 1"></i>
                <i class="main-icon" [ngClass]="child.icon"></i><span class="menuitem-label">{{child.label}}</span>
                <i class="fa fa-fw fa-angle-down menuitem-toggle-icon" *ngIf="child.items && level === 0"></i>
                <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
            </a>
            <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink"
                *ngIf="child.routerLink && child.routerLink !== '#'"
                [routerLink]              = "child.routerLink"
                [attr.tabindex]     = "!visible ? '-1' : null" [attr.target] = "child.target">
                <div class="decoration" *ngIf="level === 2">
                    <i class="fa fa-cube decorated-icon"></i>
                </div>
                <i class="fa fa-caret-right menuitem-toggle-icon" *ngIf="level === 1"></i>
                <i class="main-icon" [ngClass]="child.icon"></i><span class="menuitem-label">{{child.label}}</span>
                <i class="fa fa-fw fa-angle-down menuitem-toggle-icon" *ngIf="child.items && level === 0"></i>
                <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
            </a>
            <div class="layout-menu-tooltip">
                <div class="layout-menu-tooltip-arrow"></div>
                <div class="layout-menu-tooltip-text">{{child.label}}</div>
            </div>
            <div class="submenu-arrow" *ngIf="child.items"></div>
            <ul app-submenu [item]="child" [level]="level + 1" *ngIf="child.items && level < 2"
                [ngClass]="[
                    isNumber(level) ? 'submenu-level-' + (level + 1) : ''
                ]"
                [hidden]="child.routerLink && child.routerLink !== '#'"
                (onTogglePin)="togglePin($event)"
                [pinnedMenuIds]="pinnedMenuIds"
                [visible]="isActive(i, child)" [reset]="reset" [parentActive]="isActive(i, child)"
                [@children]="((app.isSlim()|| app.isHorizontal()) && root ? (isActive(i, child) ? 'visible' : 'hidden') : isActive(i, child)) ? 'visibleAnimated' : 'hiddenAnimated'">
            </ul>
        </li>
    </ng-template>
    