var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { ModuleConfigService } from './module-config.service';
import { PsBaseService } from './psbase.service';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
var PsThongBaoService = /** @class */ (function (_super) {
    __extends(PsThongBaoService, _super);
    function PsThongBaoService(http, injector, config) {
        return _super.call(this, http, 'config.getConfig().', injector) || this;
    }
    PsThongBaoService.prototype.sendNotify = function (myNotify) {
        var _this = this;
        var url = environment.apiDomain.notificationEndpoint + "/notifications";
        return this.http
            .post(url, myNotify)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    PsThongBaoService.prototype.guiThongBaoQuaEmail = function (thongbao) {
        var _this = this;
        var url = this.svUrl + "/GuiThongBaoQuaEmail";
        return this.http
            .post(url, thongbao)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    return PsThongBaoService;
}(PsBaseService));
export { PsThongBaoService };
