import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
var SendAccessTokenInterceptor = /** @class */ (function () {
    function SendAccessTokenInterceptor(_oauthService) {
        this._oauthService = _oauthService;
    }
    SendAccessTokenInterceptor.prototype.intercept = function (request, next) {
        request = request.clone({
            setHeaders: {
                Authorization: "Bearer " + this._oauthService.getAccessToken()
            }
        });
        return next.handle(request);
    };
    SendAccessTokenInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function SendAccessTokenInterceptor_Factory() { return new SendAccessTokenInterceptor(i0.inject(i1.OAuthService)); }, token: SendAccessTokenInterceptor, providedIn: "root" });
    return SendAccessTokenInterceptor;
}());
export { SendAccessTokenInterceptor };
