/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/summary.pipe";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/primeng/components/scrollpanel/scrollpanel.ngfactory";
import * as i5 from "primeng/components/scrollpanel/scrollpanel";
import * as i6 from "../../services/signalr.service";
import * as i7 from "../../services/module-config.service";
import * as i8 from "./app-notification.component";
import * as i9 from "../../services/user.service";
import * as i10 from "../../services/notification.service";
import * as i11 from "@angular/router";
import * as i12 from "../../services/custom-router.service";
var styles_PsAppNotificationComponent = [i0.styles];
var RenderType_PsAppNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PsAppNotificationComponent, data: {} });
export { RenderType_PsAppNotificationComponent as RenderType_PsAppNotificationComponent };
function View_PsAppNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "topbar-badge animated rubberBand"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalUnRead; _ck(_v, 1, 0, currVal_0); }); }
function View_PsAppNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["role", "menuitem"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "a", [["class", "topbar-message"], ["href", "javascript:"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "noti-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "/assets/images/avatar.jpg"], ["style", "border:2px #fff solid; border-radius:18px"], ["width", "35"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "noti-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "noti-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "noti-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "noti-time"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-clock-o"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", ""])), i1.ɵppd(13, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "noti-item ", _co.getClassRead(_v.context.$implicit), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.message.title; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.message.content, 18)); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent, 1), _v.context.$implicit.message.time, "hh:mm dd/MM/yyyy")); _ck(_v, 12, 0, currVal_3); }); }
export function View_PsAppNotificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SummaryPipe, []), i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(402653184, 1, { notificationDetail: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMenuEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "topbar-icon animated swing fa fa-fw fa-bell-o"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PsAppNotificationComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "topbar-item-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Messages"])), (_l()(), i1.ɵeld(9, 0, null, null, 11, "ul", [["class", "layout-menu fadeInDown custom-notification"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [["class", "noti-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.headerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Th\u00F4ng b\u00E1o"])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "p-scrollPanel", [], null, null, null, i4.View_ScrollPanel_0, i4.RenderType_ScrollPanel)), i1.ɵdid(14, 4374528, null, 0, i5.ScrollPanel, [i1.ElementRef, i1.NgZone], { style: [0, "style"] }, null), i1.ɵpod(15, { width: 0, height: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PsAppNotificationComponent_2)), i1.ɵdid(17, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "li", [["class", "noti-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "a", [["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.readAll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0110\u00E1nh d\u1EA5u t\u1EA5t c\u1EA3 l\u00E0 \u0111\u00E3 \u0111\u1ECDc"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.totalUnRead > 0); _ck(_v, 6, 0, currVal_0); var currVal_1 = _ck(_v, 15, 0, "100%", "350px"); _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.notifications; _ck(_v, 17, 0, currVal_2); }, null); }
export function View_PsAppNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["ps-app-notification", ""]], null, null, null, View_PsAppNotificationComponent_0, RenderType_PsAppNotificationComponent)), i1.ɵprd(512, null, i6.SignalRService, i6.SignalRService, [i7.ModuleConfigService]), i1.ɵdid(2, 114688, null, 0, i8.PsAppNotificationComponent, [i6.SignalRService, i9.UserService, i10.NotificationService, i11.Router, i12.CustomRouterService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PsAppNotificationComponentNgFactory = i1.ɵccf("li[ps-app-notification]", i8.PsAppNotificationComponent, View_PsAppNotificationComponent_Host_0, {}, { openMenu: "openMenu" }, []);
export { PsAppNotificationComponentNgFactory as PsAppNotificationComponentNgFactory };
