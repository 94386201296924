import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-Unauthorize',
    templateUrl: './Unauthorize.component.html',
    styleUrls: ['./Unauthorize.component.scss']
})
export class UnauthorizeComponent implements OnInit {

    constructor(
        // private _inspectMenuService: InspectMenuService,
    ) { }

    ngOnInit() {
        // this._inspectMenuService.getMenuItems()
        //     .then(menuItems => {
        //         this._menuService.setMenuItems(menuItems);
        //     });
    }

}
