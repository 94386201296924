
        <ul app-submenu [item]="model" [level]="0" root="true" class="ps-main-menu layout-menu layout-main-menu clearfix"
            [pinnedMenuIds]="pinnedMenuIds" (onTogglePin)="togglePin($event)" [reset]="reset" visible="true" parentActive="true"></ul>
        <ul (mouseover)="mouseOverPinnedMenu()" (mouseleave)="mouseLeavePinnedMenu()"
            class="ps-pinned-menu show" [class.show]="showPinnedMenu && pinnedMenuData && pinnedMenuData.length">
            <li class="pinned" *ngFor="let item of pinnedMenuData;let i = index" [authorize]="item.phanQuyen"
                                [isNavigationPermission]="true"
                                [permissionType]="permissionType">
                <i class="fa fa-thumb-tack pinned-icon" (click)="unpinMenuItem(item.id)" title="Bỏ ghim"></i>
                <a class="ripplelink" [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}">
                    <div class="decoration">
                        <i class="fa fa-cube decorated-icon"></i>
                    </div>
                    <span class="menuitem-label">{{item.label}}</span>
                </a>
            </li>
        </ul>
    