var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { PsBaseService } from './psbase.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry, catchError } from 'rxjs/operators';
var TNThietLapCauHinhService = /** @class */ (function (_super) {
    __extends(TNThietLapCauHinhService, _super);
    function TNThietLapCauHinhService(http, injector, httpModule) {
        var _this = _super.call(this, http, environment.apiDomain.dapFoodEndPoint + "/ThietLapCauHinh", injector) || this;
        _this.httpModule = httpModule;
        return _this;
    }
    TNThietLapCauHinhService.prototype.getCauHinh = function (ma) {
        var _this = this;
        var url = this.svUrl + "/getCauHinh/" + ma;
        return this.http
            .get(url)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    TNThietLapCauHinhService.prototype.setCauHinh = function (cauhinh) {
        var _this = this;
        var url = this.svUrl + "/setCauHinh";
        return this.http
            .post(url, cauhinh)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    return TNThietLapCauHinhService;
}(PsBaseService));
export { TNThietLapCauHinhService };
