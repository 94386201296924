import { NgModule, ModuleWithProviders, TemplateRef } from '@angular/core';
import { NgbTooltipModule, NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { PsModuleConfig } from './models/module-config';
import {
    ModuleConfigService, moduleConfigFunc
} from './services/module-config.service';
import {
    FileUploadModule,
    DropdownModule,
    TooltipModule,
    MultiSelectModule,
    AutoCompleteModule,
    CalendarModule,
    DialogModule,
    ScrollPanelModule
} from 'primeng/primeng';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PsPrintOnDebugPipe } from './pipes/print-object.pipe';
import { PsQueryStringService } from './services/query-string.service';
import { DateOnlyDirective } from './directives/date-only.directive';
import { PsCommonService } from './services/ps-common.service';
import { PsThongBaoService } from './services/ps-thongbao.service';
import { PsApplicationManagementService } from './services/application-management.service';
import { PsDateTimePipe, PsDatePipe } from './pipes/date.pipe';

import { ShowDefaultImageOnErrorDirective } from './directives/show-default-image-on-error.directive';
import { RouterModule } from '@angular/router';
import { ConfirmDirective } from './directives/confirm.directive';
import { AccountTypePipe } from './pipes/account-type.pipe';
import { ApprovalPipe } from './pipes/approval.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PsUserRolePipe } from './pipes/user-role.pipe';
import { TNThietLapCauHinhService } from './services/cau-hinh-thiet-lap.service';
import { ConfirmSwalDirective } from './directives/confirm-swal.directive';
import { ErrorInterceptor } from './intercepters/error-intercepter';
// import { AuthorizationIntercepter } from './auth/authorization-intercepter';
import { ConfigurationService } from './services/configuration.service';
// import { PhanTramPipe } from 'lib-shared';
import { LogSessionIntercepter } from './intercepters/log-session.intercepter';
import { AuthorizeDirective } from './directives/authorize.directive';
import { PsSingleFsFileUploadComponent } from './components/fs-upload/single-file-upload/single-file-upload.component';
import { PsFsFolderPickerComponent } from './components/fs-upload/fs-folder-picker/fs-folder-picker.component';
import { PsFsMultiFileUploadComponent } from './components/fs-upload/multi-file-upload/multi-file-upload.component';
import { PsFsFileUploadService } from './components/fs-upload/services/file-upload.service';
import { BreadcrumbComponent } from './components/fs-upload/breadcrumb/breadcrumb.component';
import { FileManagerPopupComponent } from './components/fs-upload/file-manager-popup/file-manager-popup.component';
import { FileViewerComponent } from './components/fs-upload/file-viewer/file-viewer.component';
import { TNSelectFileOnServerComponent } from './components/fs-upload/select-file-onserver/select-file-onserver.component';
import { PsMultiUploadComponent } from './components/fs-upload/multi-upload/multi-upload.component';
import { PsThumbnailComponent } from './components/file-upload/thumbnail/thumbnail.component';
import { PsSingleFileUploadComponent } from './components/file-upload/single-file-upload/single-file-upload.component';
import { PsMultiFileUploadComponent } from './components/file-upload/multi-file-upload/multi-file-upload.component';
import { PsListFileComponent } from './components/file-upload/list-file/list-file.component';
import { TableModule } from 'primeng/table';
import { PsFileUploadService } from './components/file-upload/services/file-upload.service';
import { AuthorizationIntercepter } from './intercepters/authorization-intercepter';
import { PsFsListFileComponent } from './components/fs-upload/list-file/list-file.component';
import { PsFsListImageComponent } from './components/fs-upload/list-image/list-image.component';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { PsAppNotificationComponent } from './components/app-notification/app-notification.component';
import { BlockableDivComponent } from './components/blockable/blockable-div/blockable-div.component';
import { BooleanFormatPipe } from './pipes/boolean-format.pipe';
import { ConvertMoneyToWordPipe } from './pipes/convertMoneyToWord.pipe';
import { FileIconPipe } from './pipes/file-icon.pipe';
import { HighlightSearchPipe } from './pipes/highlight.pipe';
import { StringFormatPipe } from './pipes/string-format.pipe';
import { SummaryPipe } from './pipes/summary.pipe';
import { TrangThaiPipe } from './pipes/trangThai.pipe';
import { PsFileSizePipe, PsGetRawFileNamePipe } from './pipes/file-size.pipe';
import { NotificationService } from './services/notification.service';
import { PsMultiFileUpload2Component } from './components/fs-upload/multi-file-upload2/multi-file-upload2.component';
import { UserService } from './services/user.service';
// import { NotifierService } from 'angular-notifier';

export function coreDeclarations() {
    return [
        PsPrintOnDebugPipe,
        PsDateTimePipe,
        PsDatePipe,
        PsUserRolePipe,
        DateOnlyDirective,
        ShowDefaultImageOnErrorDirective,
        ConfirmDirective,
        ConfirmSwalDirective,
        AccountTypePipe,
        ApprovalPipe,
        AuthorizeDirective,
        PsSingleFsFileUploadComponent,
        PsFsFolderPickerComponent,
        PsFsMultiFileUploadComponent,
        FileViewerComponent,
        BreadcrumbComponent,
        FileManagerPopupComponent,
        TNSelectFileOnServerComponent,
        PsMultiUploadComponent,
        PsThumbnailComponent,
        PsSingleFileUploadComponent,
        PsMultiFileUploadComponent,
        PsMultiFileUpload2Component,
        PsListFileComponent,
        PsFsListFileComponent,
        PsFsListImageComponent,
        ValidationSummaryComponent,
        PsAppNotificationComponent,
        BlockableDivComponent,
        BooleanFormatPipe,
        ConvertMoneyToWordPipe,
        FileIconPipe,
        PsFileSizePipe,
        PsGetRawFileNamePipe,
        HighlightSearchPipe,
        StringFormatPipe,
        SummaryPipe,
        TrangThaiPipe
    ];
}

export function coreProviders() {
    return [
        UserService,
        NotificationService,
        PsFileUploadService,
        ConfigurationService,
        ModuleConfigService,
        PsQueryStringService,
        PsCommonService,
        PsThongBaoService,
        PsApplicationManagementService,
        TNThietLapCauHinhService,
        // NotifierService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogSessionIntercepter,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationIntercepter,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        PsFsFileUploadService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ];
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        FileUploadModule,
        TooltipModule,
        MultiSelectModule,
        AutoCompleteModule,
        NgbTooltipModule,
        NgbCollapseModule,
        CalendarModule,
        NgbModule,
        DialogModule,
        TableModule,
        ScrollPanelModule

    ],
    declarations: coreDeclarations(),
    exports: coreDeclarations(),
    providers: coreProviders()
})
export class PsSharedModule {
    static forRoot(config?: any): ModuleWithProviders {
        return {
            ngModule: PsSharedModule,
            providers: [
                {
                    provide: moduleConfigFunc,
                    useValue: config
                }
            ]
        };
    }
}
