import { Injectable, Injector } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import * as JWT from 'jwt-decode';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { shareReplay, retry, catchError, map } from 'rxjs/operators';
import { ResponseResult, ResponsePagination } from '../models/response-result';
import { environment } from '../../../environments/environment';
import { PsAuthenService } from '../auth/authen.service';
import { User } from '../models/user';
import { BaseService } from './base.service';
import { PsCommonService } from './ps-common.service';
import { id } from '@swimlane/ngx-datatable/release/utils';

@Injectable()
export class UserService extends BaseService {
    readonly USER_INFO_KEY = 'user_info';
    readonly authenticationEndpoint = `${environment.apiDomain.authenticationEndpoint}/users`;
    readonly RETRY_COUNT: number = 0;
    readonly REPLAY_COUNT: number = 10;

    public tokenReceived = new Observable<any>();

    private _unSubscribeAll = new Subject<any>();

    constructor(
        http: HttpClient,
        injector: Injector,
        private _oauthService: OAuthService,
        private _commonService: PsCommonService,

    ) {
        super(http, injector, `${environment.apiDomain.dapFoodEndPoint}/Users`)
    }

    cachedUserInfo: User;

    promises = [];
    isReady = false;

    returnPromises(): void {
        while (this.promises.length > 0) {
            const pr = this.promises.pop();
            const accessToken = this._oauthService.getAccessToken();
            const decoded: any = JWT(accessToken);
            this.getCurrentUser();
        }
        this.isReady = true;
    }

    handleError(error: any, injector: Injector) {
        // console.error('Có lỗi xảy ra', error);
        if (error.status === 401) {
            const authenService = injector.get(PsAuthenService);
            authenService.logout();
        } else {
        }
        return Promise.reject(error);
    }

    changePassword(item: any): Promise<ResponseResult> {
        const apiUrl = `${this.authenticationEndpoint}/ChangePassword`;
        return this.defaultPost(apiUrl, item);
    }

    Create(obj: any) {
        const queryString = `${this.authenticationEndpoint}}/Create`;
        return this.defaultPost(queryString, obj);
    }

    Update(obj: any) {
        const queryString = `${this.authenticationEndpoint}/Update`;
        return this.defaultPost(queryString, obj);
    }

    getCaptchaUrl(): string {
        return `${this.authenticationEndpoint}}/captcha?${Date.now()}&logSession=${sessionStorage.getItem("log_session_key")}`;
    }

    getBasicUserInfo(): User {
        const crrUser = new User();
        const accessToken = this._oauthService.getAccessToken();
        if (accessToken) {
            const claims: any = JWT(accessToken);

            if (claims) {
                crrUser.displayName = claims.displayname;
                crrUser.email = claims.email;
                crrUser.fullName = claims.firstname.concat(' ', claims.lastname);
                crrUser.issuperuser = claims.issuperuser.toLowerCase() === 'true';
                crrUser.permissions = claims.permissions;
                crrUser.scope = claims.scope;
                crrUser.userId = claims.sub;
                crrUser.userName = claims.username;
                crrUser.avatar = this._commonService.getFileUrl(claims.avatar);
            }
        }
        return crrUser;
    }

    async getCurrentUser(): Promise<User> {
        const crrUser = new User();
        const accessToken = this._oauthService.getAccessToken();
        if (accessToken) {
            const claims: any = JWT(accessToken);

            if (claims) {
                crrUser.displayName = claims.displayname;
                crrUser.email = claims.email;
                crrUser.fullName = claims.firstname.concat(' ', claims.lastname);
                crrUser.issuperuser = claims.issuperuser.toLowerCase() === 'true';
                crrUser.permissions = claims.permissions;
                crrUser.scope = claims.scope;
                crrUser.userId = claims.sub;
                crrUser.userName = claims.username;
                crrUser.avatar = this._commonService.getFileUrl(claims.avatar);

                if (localStorage.getItem(this.USER_INFO_KEY)) {
                    try {
                        return JSON.parse(localStorage.getItem(this.USER_INFO_KEY));
                    } catch (e) {

                    }
                } else {
                    await this.getCurrent().then(rs => {
                        if (rs.status) {
                            crrUser.email = rs.data.email;
                            crrUser.avatar = rs.data.avatar;
                            crrUser.id = rs.data.id;
                            crrUser.idClient = rs.data.idClient;
                            crrUser.name = rs.data.name;
                            crrUser.phone = rs.data.phone;
                            crrUser.idProvince = rs.data.idProvince;
                            crrUser.idDistrict = rs.data.idDistrict;
                            crrUser.idWard = rs.data.idWard;
                            crrUser.address = rs.data.address;
                            crrUser.isDeleted = rs.data.isDeleted;

                            localStorage.setItem(this.USER_INFO_KEY, JSON.stringify(crrUser));
                        }
                    });
                }
                return JSON.parse(localStorage.getItem(this.USER_INFO_KEY));
            }
        }

        return crrUser;
    }

    search(term: string
        , isActive: number
        , pageIndex: number
        , pageSize: number
        , roleId: number
        , isDisabled: number
        , isSuperUser: number
    ): Promise<ResponsePagination<any[]>> {
        return this._http.get<ResponsePagination<any[]>>
            (`${this.serviceUri}/managerment?pageIndex=${pageIndex}&pageSize=${pageSize}&term=${term}
    &isActive=${isActive}&roleId=${roleId}&isDisabled=${isDisabled}&isSuperUser=${isSuperUser}`).toPromise();
    }

    Gets(key: string, offset?: number, limit?: number, orderCol: string = '', orderType: Number = 0) {
        const queryString = `${this.serviceUri}?key=${key}&offset=${offset}&limit=${limit}&orderCol=${orderCol}&orderType=${orderType}`;
        return this.defaultGet(queryString);
    }

    get(key: string, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false) {
        const queryString = `${this.serviceUri}?key=${key}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }

    getCurrent() {
        return this.defaultGet(`${this.serviceUri}/getCurrent`);
    }
}
