var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { GlobalService } from './services/global.service';
import { CustomRouterService } from './lib-shared/services/custom-router.service';
import { NotifierService } from './lib-shared/services/notifier.service';
import { CommonService } from './lib-shared/services/common.service';
import { User } from './lib-shared/models/user';
import { UserService } from './lib-shared/services/user.service';
var AppTopBarComponent = /** @class */ (function () {
    function AppTopBarComponent(app, _activatedRoute, _customRouteService, _router, _userService, _globalService, notifierService, _commonService) {
        this.app = app;
        this._activatedRoute = _activatedRoute;
        this._customRouteService = _customRouteService;
        this._router = _router;
        this._userService = _userService;
        this._globalService = _globalService;
        this.notifierService = notifierService;
        this._commonService = _commonService;
        this.environment = environment;
        this.searchKey = '';
        this._unSubscribeAll = new Subject();
        this.currentRoute = '';
        this.fileApi = '';
        this.fullName = '';
        this.position = '';
        this.avatarUrl = 'assets/images/avatar.jpg';
        this.currentUser = new User;
        this.arrHe = [];
        this.namHoc_options = [];
        this.hocKy_options = [];
        this.dotThi_options = [];
        this.formGroup = [];
        this.fileApi = this.environment.apiDomain.fileEndpoint;
    }
    AppTopBarComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // if (this.mySetting.idDotThi == null) {
                        //     this.display = true;
                        // }
                        _a = this;
                        return [4 /*yield*/, this._userService.getCurrentUser()];
                    case 1:
                        // if (this.mySetting.idDotThi == null) {
                        //     this.display = true;
                        // }
                        _a.currentUser = _b.sent();
                        this.fullName = this.currentUser.fullName;
                        this.getImageUrl();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTopBarComponent.prototype.getImageUrl = function () {
        if (this.currentUser && this.currentUser.avatar) {
            this.avatarUrl = this.fileApi + "/upload?file=" + this.currentUser.avatar;
        }
        else {
            this.avatarUrl = 'assets/images/avatar.jpg';
        }
    };
    AppTopBarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // first time
        var url = top.location.href;
        if (url.indexOf('#searchKey=') > -1) {
            var obj = this._customRouteService.parseFragment(url.split('#')[1]);
            if (obj.searchKey) {
                this.searchKey = decodeURIComponent(obj.searchKey.trim());
            }
        }
        this._sub = this._router.events.pipe(filter(function (e) { return e instanceof NavigationEnd; })).subscribe(function (r) {
            var fragment = _this._customRouteService.parseFragment(location.hash.substring(1));
            if (fragment.searchKey) {
                _this.searchKey = decodeURIComponent(fragment.searchKey);
            }
            else {
                _this.searchKey = '';
            }
            _this._globalService.setSearchBoxState(true);
        });
    };
    AppTopBarComponent.prototype.ngOnDestroy = function () {
        this._unSubscribeAll.next();
        this._unSubscribeAll.complete();
        if (this._sub) {
            this._sub.unsubscribe();
        }
    };
    AppTopBarComponent.prototype.onChangeHe = function (item) {
        var dataitem = this.arrHe.filter(function (x) { return x.id === item.value; })[0];
        // this.mySetting.tenHe = dataitem.ten;
        // this.mySetting.khHe = dataitem.ma;
        // this.mySetting.idKhoaHoc = 0;
        // this.mySetting.tenKhoaHoc = 'Chọn khóa học';
        // this.onGetKhoaHoc();
        // this.onGetKhoa();
    };
    AppTopBarComponent.prototype.onTopbarSearchClick = function (event, searchKey) {
        if (searchKey.value !== '') {
            this._router.navigate([location.pathname], { relativeTo: this._activatedRoute, fragment: "searchKey=" + searchKey.value });
        }
        else {
            this._router.navigate([location.pathname], { relativeTo: this._activatedRoute });
        }
    };
    AppTopBarComponent.prototype.showDialog = function () {
        this.display = true;
    };
    AppTopBarComponent.prototype.onCloseDialog = function () {
        this.display = false;
    };
    AppTopBarComponent.prototype.onSaveSettings = function () {
        // const namhoc = this.namHoc_options.find(s => s.value === this.mySettingEdit.idNamHoc);
        // const hocky = this.hocKy_options.find(s => s.value === this.mySettingEdit.idHocKy);
        // const dothi = this.dotThi_options.find(s => s.value === this.mySettingEdit.idDotThi);
        // if (namhoc == null || hocky == null || dothi == null) {
        //     this.notifierService.showError('Bạn phải chọn đợt thi');
        // } else {
        // this.mySetting.idNamHoc = namhoc.value;
        // this.mySetting.idHocKy = hocky.value;
        // this.mySetting.idDotThi = dothi.value;
        // this.mySetting.tenNamHoc = namhoc.label;
        // this.mySetting.khHocKy = hocky.label;
        // this.mySetting.tenDotThi = dothi.label;
        // this._mySettingService.setCurrentSetting(this.mySetting);
        // this.notifierService.showSuccess('success', 'Lưu thiết lập thành công');
        // this.onCloseDialog();
        // window.location.reload();
        // }
    };
    AppTopBarComponent.prototype.onReturn = function () {
        // if (this.mySetting.idNamHoc == null || this.mySetting.idHocKy == null || this.mySetting.idDotThi == null) {
        //     this.notifierService.showError('Bạn chưa thiết lập đợt thi');
        //     return;
        // } else {
        //     this.onCloseDialog();
        // }
    };
    return AppTopBarComponent;
}());
export { AppTopBarComponent };
