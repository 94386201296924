var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ModuleConfigService } from './module-config.service';
var SignalRService = /** @class */ (function () {
    function SignalRService(_moduleConfigService) {
        this._moduleConfigService = _moduleConfigService;
        this.maxRetryTimes = 10;
        this.wait = function (ms) { return new Promise(function (r) { return setTimeout(r, ms); }); };
        // this._moduleConfig = this._moduleConfigService.getConfig();
    }
    SignalRService.prototype.start = function (topic, viewCode, callback) {
        // this.createConnection(topic);
        // this.startConnection().then(rs => {
        //     this.subscribeViewCode(viewCode, callback);
        //     this.autoReconnect(topic);
        // });
    };
    SignalRService.prototype.stop = function () {
        this._hubConnection.stop();
    };
    SignalRService.prototype.subscribeViewCode = function (viewCode, callback) {
        if (this.viewCodeSubs !== undefined) {
            this.viewCodeSubs.push({ viewCode: viewCode, callback: callback });
        }
        else {
            this.viewCodeSubs = [{ viewCode: viewCode, callback: callback }];
        }
        this.subscribe(viewCode, callback);
    };
    SignalRService.prototype.createConnection = function (topic) {
        // this._hubConnection = new signalR.HubConnectionBuilder()
        //     .withUrl(`${this._moduleConfig.Services.NotificationEndpoint}/${topic}`,
        //         { accessTokenFactory: () => this._authenService.getAccessToken() })
        //     .build();
    };
    SignalRService.prototype.autoReconnect = function (topic) {
        var _this = this;
        this._hubConnection.onclose(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.startConnection()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SignalRService.prototype.startConnection = function (retryCount) {
        var _this = this;
        if (retryCount === void 0) { retryCount = 1; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // not sure about it :)))
                // https://github.com/aspnet/SignalR/issues/2389
                Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
                this._hubConnection
                    .start().then(function () {
                    resolve(true);
                })
                    .catch(function (err) {
                    if (_this.maxRetryTimes - retryCount > 0) {
                        return _this.wait(retryCount * retryCount * 1000)
                            .then(_this.startConnection.bind(_this, ++retryCount))
                            .then(resolve)
                            .catch(reject);
                    }
                    return reject(err);
                });
                return [2 /*return*/];
            });
        }); });
    };
    SignalRService.prototype.subscribe = function (viewCode, callback) {
        this._hubConnection.invoke('JoinRoom', viewCode).catch(function (err) { return console.error(err.toString()); });
        this._hubConnection.on(viewCode, function (data) {
            callback(data);
        });
    };
    return SignalRService;
}());
export { SignalRService };
