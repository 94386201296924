import { Injectable, Injector } from '@angular/core';

import { PsBaseService, ResponseResult, ResponseSingleResult } from './psbase.service';
import { retry, catchError } from 'rxjs/operators';
import { Notification } from '../models/notification';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class NotificationService extends PsBaseService<Notification> {
    constructor(http: HttpClient, injector: Injector) {
        super(http, `${environment.apiDomain.notificationEndpoint}/Notifications`, injector);
    }
    search(type: number, time: number): Promise<ResponseResult> {
        const url = `${this.svUrl}/${type}/${time}`;
        return this.http.get<ResponseResult>(url)
            .pipe(retry(3), catchError(err => this.handleError(err, this.injector))).map(rs => rs).toPromise();
    }
    getFavourite(id: number, type: number, time: number): Promise<ResponseResult> {
        const url = `${this.svUrl}/Favourite/${type}/${time}/${id}`;
        return this.http.get<ResponseResult>(url)
            .pipe(retry(3), catchError(err => this.handleError(err, this.injector))).map(rs => rs).toPromise();
    }
    unRead(type: number): Promise<ResponseSingleResult> {
        const url = `${this.svUrl}/UnRead/${type}`;
        return this.http.get<ResponseSingleResult>(url)
            .pipe(retry(3), catchError(err => this.handleError(err, this.injector))).map(rs => rs).toPromise();
    }
    getById(id: string): Promise<ResponseResult> {
        const url = `${this.svUrl}/Read/${id}`;
        return this.http.get<ResponseResult>(url)
            .pipe(retry(3), catchError(err => this.handleError(err, this.injector))).map(rs => rs).toPromise();
    }
    readAll(): Promise<ResponseResult> {
        const url = `${this.svUrl}/ReadAll`;
        return this.http.get<ResponseResult>(url)
            .pipe(retry(3), catchError(err => this.handleError(err, this.injector))).map(rs => rs).toPromise();
    }

}

