import { OnInit, EventEmitter, Injector } from '@angular/core';
import { AppComponent } from './app.component';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { PermissionTypes } from './lib-shared/ps-constants';
import { HtMenuService } from './lib-shared/services/htmenu.service';
import { NotifierService } from './lib-shared/services/notifier.service';
var AppMenuComponent = /** @class */ (function () {
    function AppMenuComponent(app, _injector) {
        this.app = app;
        this._injector = _injector;
        this._unsubscribeAll = new Subject();
        this.theme = 'blue';
        this.layout = 'blue';
        this.version = 'v3';
        this.onMouseOverPinnedMenu = new EventEmitter();
        this.onMouseLeavePinnedMenu = new EventEmitter();
        // danh sách id của các menu item được ghim
        this.pinnedMenuIds = [];
        this.pinnedMenuData = [];
        this.permissionType = PermissionTypes.PAGE;
        this._notifierService = this._injector.get(NotifierService);
        this._translateService = this._injector.get(TranslateService);
        this._menuService = this._injector.get(HtMenuService);
    }
    AppMenuComponent.prototype.ngOnInit = function () {
        // this.getPinnedMenuData();
    };
    AppMenuComponent.prototype.changeTheme = function (theme) {
        var themeLink = document.getElementById('theme-css');
        if (this.version === 'v3') {
            themeLink.href = 'assets/theme/theme-' + theme + '.css';
        }
        else {
            themeLink.href = 'assets/theme/theme-' + theme + '-v4' + '.css';
        }
        this.theme = theme;
    };
    AppMenuComponent.prototype.changeLayout = function (layout, special) {
        var layoutLink = document.getElementById('layout-css');
        if (this.version === 'v3') {
            layoutLink.href = 'assets/layout/css/layout-' + layout + '.css';
        }
        else {
            layoutLink.href = 'assets/layout/css/layout-' + layout + '-v4' + '.css';
        }
        this.layout = layout;
        if (special) {
            this.app.darkMenu = true;
        }
    };
    AppMenuComponent.prototype.changeVersion = function (version) {
        var themeLink = document.getElementById('theme-css');
        var layoutLink = document.getElementById('layout-css');
        if (version === 'v3') {
            this.version = 'v3';
            themeLink.href = 'assets/theme/theme-' + this.theme + '.css';
            layoutLink.href = 'assets/layout/css/layout-' + this.layout + '.css';
        }
        else {
            themeLink.href = 'assets/theme/theme-' + this.theme + '-v4' + '.css';
            layoutLink.href = 'assets/layout/css/layout-' + this.layout + '-v4' + '.css';
            this.version = '-v4';
        }
    };
    AppMenuComponent.prototype.mouseOverPinnedMenu = function () {
        this.onMouseOverPinnedMenu.emit(null);
    };
    AppMenuComponent.prototype.mouseLeavePinnedMenu = function () {
        this.onMouseLeavePinnedMenu.emit(null);
    };
    AppMenuComponent.prototype.togglePin = function (id) {
        if (this.pinnedMenuIds.includes(id)) {
            this.unpinMenuItem(id);
        }
        else {
            this.pinMenuItem(id);
        }
    };
    AppMenuComponent.prototype.unpinMenuItem = function (id) {
        var _this = this;
        this._menuService.unPinMenuItem(id)
            .then(function (rs) {
            if (rs.status) {
                _this.getPinnedMenuData(true);
            }
        }, function (error) {
            _this._notifierService.showHttpUnknowError();
        });
    };
    AppMenuComponent.prototype.pinMenuItem = function (id) {
        var _this = this;
        this._menuService.pinMenuItem(id)
            .then(function (rs) {
            if (rs.status) {
                _this.getPinnedMenuData(true);
            }
        }, function (error) {
            _this._notifierService.showHttpUnknowError();
        });
    };
    AppMenuComponent.prototype.getPinnedMenuData = function (changed) {
        var _this = this;
        if (changed === void 0) { changed = false; }
        if (changed) {
            this._menuService.getsPinMenu(environment.clientDomain.idPhanhe).then(function (rs) {
                if (rs.status) {
                    var data = rs.data;
                    _this.pinnedMenuData = data ? data : [];
                    _this.pinnedMenuIds = data.map(function (item) { return item.id; });
                    sessionStorage.setItem('htMenuPinned' + environment.clientDomain.idPhanhe.toString(), JSON.stringify(rs.data));
                }
            });
        }
        else {
            var cacheVal = sessionStorage.getItem('htMenuPinned' + environment.clientDomain.idPhanhe.toString());
            if (cacheVal !== undefined && cacheVal !== null) {
                var data = JSON.parse(cacheVal);
                this.pinnedMenuData = data ? data : [];
                this.pinnedMenuIds = data.map(function (item) { return item.id; });
            }
            else {
                this._menuService.getsPinMenu(environment.clientDomain.idPhanhe).then(function (rs) {
                    if (rs.status) {
                        var data = rs.data;
                        _this.pinnedMenuData = data ? data : [];
                        _this.pinnedMenuIds = data.map(function (item) { return item.id; });
                        sessionStorage.setItem('htMenuPinned' + environment.clientDomain.idPhanhe.toString(), JSON.stringify(rs.data));
                    }
                });
            }
        }
    };
    return AppMenuComponent;
}());
export { AppMenuComponent };
var AppSubMenuComponent = /** @class */ (function () {
    function AppSubMenuComponent(app, _router) {
        this.app = app;
        this._router = _router;
        this.permissionType = PermissionTypes.PAGE;
        this.isFirstLoad = true;
        this.onTogglePin = new EventEmitter();
    }
    AppSubMenuComponent.prototype.itemClick = function (event, item, index) {
        var _this = this;
        this.isFirstLoad = false;
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;
        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }
        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(function () {
                _this.app.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }
        // hide menu
        if (!item.items) {
            if (this.app.isHorizontal() || this.app.isSlim()) {
                this.app.resetMenu = true;
            }
            else {
                this.app.resetMenu = false;
            }
            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
        if (item.url || item.routerLink) {
            this.activeIndex = null;
            this.isFirstLoad = true;
        }
    };
    AppSubMenuComponent.prototype.onMouseEnter = function (index) {
        if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    };
    AppSubMenuComponent.prototype.isActive = function (index, currentItem) {
        if (!this.isFirstLoad)
            return this.activeIndex == index;
        if (currentItem && currentItem.routerLink) {
            if (this.isSameWithCurrentPageURL(currentItem.routerLink)) {
                this.activeIndex = index;
                return true;
            }
        }
        if (currentItem && currentItem.items) {
            for (var i in currentItem.items) {
                var childItem = currentItem.items[i];
                if (this.isActive(index, childItem)) {
                    return true;
                }
            }
        }
        return false;
    };
    AppSubMenuComponent.prototype.isSameWithCurrentPageURL = function (routerLink) {
        var currentUrl = this._router.url;
        if (routerLink === currentUrl) {
            return true;
        }
        var currentUrlWithoutParam = currentUrl;
        if (currentUrlWithoutParam.includes('?')) {
            currentUrlWithoutParam = currentUrlWithoutParam.substr(0, currentUrlWithoutParam.indexOf('?'));
        }
        currentUrlWithoutParam = currentUrlWithoutParam.replace(/\d+/g, function (str) { return '--'; });
        return currentUrlWithoutParam === routerLink;
    };
    AppSubMenuComponent.prototype.isNumber = function (element) {
        return !isNaN(element);
    };
    AppSubMenuComponent.prototype.togglePin = function (id) {
        this.onTogglePin.emit(id);
    };
    Object.defineProperty(AppSubMenuComponent.prototype, "reset", {
        get: function () {
            return this._reset;
        },
        set: function (val) {
            this._reset = val;
            if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
                this.activeIndex = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSubMenuComponent.prototype, "parentActive", {
        get: function () {
            return this._parentActive;
        },
        set: function (val) {
            this._parentActive = val;
            if (!this._parentActive) {
                this.activeIndex = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    return AppSubMenuComponent;
}());
export { AppSubMenuComponent };
