/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./Unauthorize.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./Unauthorize.component";
var styles_UnauthorizeComponent = [i0.styles];
var RenderType_UnauthorizeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthorizeComponent, data: {} });
export { RenderType_UnauthorizeComponent as RenderType_UnauthorizeComponent };
export function View_UnauthorizeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" B\u1EA1n kh\u00F4ng c\u00F3 quy\u1EC1n truy c\u1EADp trang n\u00E0y\n"]))], null, null); }
export function View_UnauthorizeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-Unauthorize", [], null, null, null, View_UnauthorizeComponent_0, RenderType_UnauthorizeComponent)), i1.ɵdid(1, 114688, null, 0, i2.UnauthorizeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthorizeComponentNgFactory = i1.ɵccf("app-Unauthorize", i2.UnauthorizeComponent, View_UnauthorizeComponent_Host_0, {}, {}, []);
export { UnauthorizeComponentNgFactory as UnauthorizeComponentNgFactory };
