<div class="topbar clearfix">
    <div class="topbar-left">
        <div class="topbar-logo-wrapper">
            <a href="/" (click)="app.onSwitchModule(1)"><img src="{{environment.appMetadata.appDomain.logo}}"
                    class="topbar-logo" /></a>
        </div>
        <!-- <div class="topbar-title">
            <div class="topbar-description">
                {{environment.appMetadata.qlht.title}}
            </div>
            <div class="topbar-owner">
                {{environment.appMetadata.qlht.owner}}
            </div>
        </div> -->
    </div>

    <div class="topbar-right">
        <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)"
            [ngClass]="{'menu-button-rotate': app.rotateMenuButton}">
            <i class="fa fa-angle-left"></i>
        </a>

        <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
            <i class="fa fa-bars"></i>
        </a>

        <!-- <div class="breadcrumb breadcrumb-term">
            <a class="breadcrumb-item" (click)="showDialog()">
                <span class="text-xs"> {{mySetting.tenNamHoc}} / {{mySetting.khHocKy}} / {{mySetting.tenDotThi}}</span>
                <button type="button" class="btn" data-toggle="modal" data-target="#exampleModal">
                    <i class="fa fa-caret-down"></i>
                </button>
            </a>
        </div> -->

        <ul class="topbar-items fadeInDown" [ngClass]="{'topbar-items-visible': _commonService.topbarMenuActive}">
            <li #profile class="profile-item" *ngIf="app.profileMode==='top'||_commonService.isHorizontal()"
                [ngClass]="{'active-top-menu':_commonService.activeTopbarItem === profile}">

                <a href="#" (click)="_commonService.onTopbarItemClick($event,profile)" class="profile-item-inner">
                    <div class="profile-image-wrapper">
                        <img class="profile-image" src="{{avatarUrl}}" pTooltip="Thông tin cá nhân"
                            tooltipPosition="bottom" />
                    </div>
                    <div class="profile-info-wrapper">
                        <div class="topbar-item-name-wrapper"><span class="topbar-item-name">{{fullName}}</span></div>
                        <div class="topbar-item-role-wrapper"><span class="topbar-item-role">{{position}}</span></div>
                    </div>
                </a>

                <ul class="layout-menu fadeInDown">
                    <li role="menuitem">
                        <a href="#" (click)="app.onEditInfo($event)">
                            <i class="fas fa-fw fa-user-edit"></i>
                            <span>Thông tin cá nhân</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onChangePassword($event)">
                            <i class="fa fa-fw fa-key"></i>
                            <span>Đổi mật khẩu</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="javascript:;" (click)="app.onTopbarLogout($event)">
                            <i class="fa fa-fw fa-sign-out"></i>
                            <span>Đăng xuất</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li #messages [ngClass]="{'active-top-menu':_commonService.activeTopbarItem === messages}"
                ps-app-notification (openMenu)="_commonService.onTopbarItemClick($event,messages)" pTooltip="Thông báo"
                tooltipPosition="bottom">
            </li>
            <li #modules [ngClass]="{'active-top-menu':_commonService.activeTopbarItem === modules}">
                <a href="#" (click)="_commonService.onTopbarItemClick($event,modules)">
                    <i class="topbar-icon fas fa-fw fa-th-large" pTooltip="Danh sách phân hệ"
                        tooltipPosition="bottom"></i>
                    <span class="topbar-item-name">{{ 'Danh sách phân hệ' | translate}}</span>
                </a>
                <ul class="layout-menu subsystem fadeInDown">
                    <li role="menuitem" style="text-align: center;">
                        <a *ngFor="let item of app.listAppNav" target="_blank" href="{{item.url}}"
                            class="subsystem-item">
                            <span class="{{item.icon}}" style="font-size:40px"></span>
                            <span>{{ item.text | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li #search *ngIf="false" class="search-item" [hidden]="!_globalService.getSearchBoxState()"
                [ngClass]="{'active-top-menu':app.activeTopbarItem === search}" style="margin-right:10px;"
                (click)="_commonService.onTopbarItemClick($event,search)">
                <div class="ui-inputgroup" style="margin-top:-7px">
                    <button (click)="onTopbarSearchClick($event, input)" pButton type="button" icon="fa fa-search"
                        class="ui-button-secondary" style="border-right:0;color:#666"></button>
                    <input #input (keyup.enter)="onTopbarSearchClick($event, input)" type="text" pInputText
                        placeholder="Nhập từ khóa" style="height:35px" [(ngModel)]="searchKey">
                </div>
            </li>
        </ul>
    </div>
</div>
<!-- 
<p-dialog header="Thiết lập tham số hệ thống" [(visible)]="display" [modal]="true" [responsive]="true" [width]="500"
    [closeOnEscape]="true" [dismissableMask]="true" [minY]="70" [baseZIndex]="1008" [closable]="true"
    [dismissableMask]="true" [showHeader]="true" [resizable]="true">
   

    <div class="ps-form-container default-control-label-width ps-modal-content-panel">
        <div class="ps-form-container default-control-label-width">

            <div class="ui-g row">
                <div class="ui-g-12 ui-lg12">
                    <div class="ps-form-control ui-g">
                        <div class="__form-control-label ui-g-12 ui-md-4">{{'Năm học' | translate}}</div>
                        <div class="__form-control-inputs ui-g-12 ui-md-8">
                            <div class="__input-wrapper">
                                <p-dropdown [baseZIndex]="15000" [(ngModel)]="mySettingEdit.idNamHoc"
                                    (onChange)="onChangeNamHoc()"
                                    [emptyFilterMessage]="'Không tìm thấy kết quả' | translate"
                                    [options]="namHoc_options" [style]="{'width':'100%', 'min-width':'0'}"
                                    appendTo="body"></p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ui-g-12 ui-lg-12">
                    <div class="ps-form-control ui-g">
                        <div class="__form-control-label ui-g-12 ui-md-4">{{'Học kỳ' | translate}}</div>
                        <div class="__form-control-inputs ui-g-12 ui-md-8">
                            <div class="__input-wrapper">
                                <p-dropdown [baseZIndex]="15000" [(ngModel)]="mySettingEdit.idHocKy"
                                    (onChange)="onChangeNamHoc()"
                                    [emptyFilterMessage]="'Không tìm thấy kết quả' | translate"
                                    [options]="hocKy_options" [style]="{'width':'100%', 'min-width':'0'}"
                                    appendTo="body"></p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ui-g-12 ui-lg-12">
                    <div class="ps-form-control ui-g">
                        <div class="__form-control-label ui-g-12 ui-md-4">{{'Đợt thi' | translate}}</div>
                        <div class="__form-control-inputs ui-g-12 ui-md-8">
                            <div class="__input-wrapper">
                                <p-dropdown [baseZIndex]="15000" [(ngModel)]="mySettingEdit.idDotThi"
                                    [emptyFilterMessage]="'Không tìm thấy kết quả' | translate"
                                    [options]="dotThi_options" [style]="{'width':'100%', 'min-width':'0'}"
                                    appendTo="body"></p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <p-footer>
        <button type="button" class="ui-button-primary " pButton icon="pi pi-save" (click)="onSaveSettings()"
            label="Lưu lại"></button>
        <button type="button" pButton icon="pi pi-times" (click)="onReturn()" label="Đóng"
            class="ui-button-secondary"></button>
    </p-footer>
</p-dialog> -->