import { ActivatedRoute, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var CustomRouterService = /** @class */ (function () {
    function CustomRouterService(_activatedRoute, _router) {
        this._activatedRoute = _activatedRoute;
        this._router = _router;
    }
    CustomRouterService.prototype.openChildPage = function (childPageRouter, navExtra) {
        if (navExtra === void 0) { navExtra = {}; }
        this._router.navigate([childPageRouter], navExtra);
    };
    CustomRouterService.prototype.openPage = function (pageRouter, navExtra) {
        if (navExtra === void 0) { navExtra = {}; }
        this._router.navigate([pageRouter], navExtra);
    };
    CustomRouterService.prototype.backToParentPage = function () {
        this._router.navigate(['..'], { relativeTo: this._activatedRoute });
    };
    CustomRouterService.prototype.openCreatePage = function (navExtra) {
        if (navExtra === void 0) { navExtra = {}; }
        this._router.navigate(['./create'], navExtra);
    };
    CustomRouterService.prototype.openEditPage = function (id, navExtra) {
        if (navExtra === void 0) { navExtra = {}; }
        this._router.navigate(["./edit/" + id], navExtra);
    };
    CustomRouterService.prototype.parseFragment = function (fragmentString) {
        if (!fragmentString || fragmentString === '') {
            return {};
        }
        var urlSearchParam = fragmentString.split('&');
        var obj = {};
        for (var index in urlSearchParam) {
            var item = urlSearchParam[index].split('=');
            obj[item[0]] = item[1];
        }
        return obj;
    };
    CustomRouterService.prototype.getUrlWithoutParamAndFragment = function () {
    };
    CustomRouterService.ngInjectableDef = i0.defineInjectable({ factory: function CustomRouterService_Factory() { return new CustomRouterService(i0.inject(i1.ActivatedRoute), i0.inject(i1.Router)); }, token: CustomRouterService, providedIn: "root" });
    return CustomRouterService;
}());
export { CustomRouterService };
