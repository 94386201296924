import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';
import { GlobalService } from './services/global.service';
import { CustomRouterService } from './lib-shared/services/custom-router.service';
import { NotifierService } from './lib-shared/services/notifier.service';
import { CommonService } from './lib-shared/services/common.service';
import { User } from './lib-shared/models/user';
import { UserService } from './lib-shared/services/user.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit, OnDestroy, AfterViewInit {
    environment = environment;
    searchKey = '';

    _unSubscribeAll = new Subject<any>();
    _sub: Subscription;
    currentRoute = '';
    fileApi = '';

    fullName = '';
    position = '';
    avatarUrl = 'assets/images/avatar.jpg';
    currentUser = new User;
    display: any;
    arrHe = [];
    namHoc_options = [];
    hocKy_options = [];
    dotThi_options = [];
    formGroup = [];

    constructor(
        public app: AppComponent,
        private _activatedRoute: ActivatedRoute,
        private _customRouteService: CustomRouterService,
        private _router: Router,
        private _userService: UserService,
        public _globalService: GlobalService,
        private notifierService: NotifierService,
        public _commonService: CommonService
    ) {
        this.fileApi = this.environment.apiDomain.fileEndpoint;
    }

    async ngOnInit() {

        // if (this.mySetting.idDotThi == null) {
        //     this.display = true;
        // }

        this.currentUser = await this._userService.getCurrentUser();
        this.fullName = this.currentUser.fullName;
        this.getImageUrl();
    }

    getImageUrl() {
        if (this.currentUser && this.currentUser.avatar) {
            this.avatarUrl = `${this.fileApi}/upload?file=${this.currentUser.avatar}`;
        } else {
            this.avatarUrl = 'assets/images/avatar.jpg';
        }
    }


    ngAfterViewInit(): void {
        // first time
        const url = top.location.href;
        if (url.indexOf('#searchKey=') > -1) {
            const obj = this._customRouteService.parseFragment(url.split('#')[1]);
            if (obj.searchKey) {
                this.searchKey = decodeURIComponent(obj.searchKey.trim());
            }
        }

        this._sub = this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((r: NavigationEnd) => {
            const fragment = this._customRouteService.parseFragment(location.hash.substring(1));

            if (fragment.searchKey) {
                this.searchKey = decodeURIComponent(fragment.searchKey);
            } else {
                this.searchKey = '';
            }

            this._globalService.setSearchBoxState(true);
        });
    }

    ngOnDestroy(): void {
        this._unSubscribeAll.next();
        this._unSubscribeAll.complete();
        if (this._sub) {
            this._sub.unsubscribe();
        }
    }

    onChangeHe(item: any) {
        const dataitem = this.arrHe.filter(x => x.id === item.value)[0];
        // this.mySetting.tenHe = dataitem.ten;
        // this.mySetting.khHe = dataitem.ma;
        // this.mySetting.idKhoaHoc = 0;
        // this.mySetting.tenKhoaHoc = 'Chọn khóa học';
        // this.onGetKhoaHoc();
        // this.onGetKhoa();

    }

    onTopbarSearchClick(event, searchKey) {
        if (searchKey.value !== '') {
            this._router.navigate([location.pathname], { relativeTo: this._activatedRoute, fragment: `searchKey=${searchKey.value}` });
        } else {
            this._router.navigate([location.pathname], { relativeTo: this._activatedRoute });
        }
    }

    showDialog() {
        this.display = true;
    }

    onCloseDialog() {
        this.display = false;
    }

    onSaveSettings() {
        // const namhoc = this.namHoc_options.find(s => s.value === this.mySettingEdit.idNamHoc);
        // const hocky = this.hocKy_options.find(s => s.value === this.mySettingEdit.idHocKy);
        // const dothi = this.dotThi_options.find(s => s.value === this.mySettingEdit.idDotThi);
        // if (namhoc == null || hocky == null || dothi == null) {
        //     this.notifierService.showError('Bạn phải chọn đợt thi');
        // } else {
        // this.mySetting.idNamHoc = namhoc.value;
        // this.mySetting.idHocKy = hocky.value;
        // this.mySetting.idDotThi = dothi.value;

        // this.mySetting.tenNamHoc = namhoc.label;
        // this.mySetting.khHocKy = hocky.label;
        // this.mySetting.tenDotThi = dothi.label;
        // this._mySettingService.setCurrentSetting(this.mySetting);
        // this.notifierService.showSuccess('success', 'Lưu thiết lập thành công');
        // this.onCloseDialog();
        // window.location.reload();
        // }
    }

    onReturn() {
        // if (this.mySetting.idNamHoc == null || this.mySetting.idHocKy == null || this.mySetting.idDotThi == null) {
        //     this.notifierService.showError('Bạn chưa thiết lập đợt thi');
        //     return;
        // } else {
        //     this.onCloseDialog();
        // }
    }

}
