import { MessageService, ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/common/messageservice";
import * as i2 from "primeng/components/common/confirmationservice";
import * as i3 from "@ngx-translate/core";
var NotifierService = /** @class */ (function () {
    function NotifierService(_messageService, _confirmService, _translateService) {
        this._messageService = _messageService;
        this._confirmService = _confirmService;
        this._translateService = _translateService;
    }
    NotifierService.prototype.Clear = function () {
        this._messageService.clear();
    };
    NotifierService.prototype.showSuccess = function (message, header, life) {
        if (header === void 0) { header = ''; }
        if (life === void 0) { life = 10000; }
        this._messageService.add({
            severity: 'success',
            summary: header,
            detail: message,
            life: life
        });
    };
    NotifierService.prototype.showInsertDataSuccess = function (life) {
        if (life === void 0) { life = 10000; }
        this._messageService.add({
            severity: 'success',
            summary: this._translateService.instant('MESSAGE.INSERT_DATA_SUCCESS'),
            detail: this._translateService.instant('MESSAGE.COMMON_SUCCESS_HEADER'),
            life: life
        });
    };
    NotifierService.prototype.showUpdateDataSuccess = function (life) {
        if (life === void 0) { life = 10000; }
        this._messageService.add({
            severity: 'success',
            summary: this._translateService.instant('MESSAGE.UPDATE_DATA_SUCCESS'),
            detail: this._translateService.instant('MESSAGE.COMMON_SUCCESS_HEADER'),
            life: life
        });
    };
    NotifierService.prototype.showDeleteDataSuccess = function (life) {
        if (life === void 0) { life = 5000; }
        this._messageService.add({
            severity: 'success',
            summary: this._translateService.instant('MESSAGE.DELETE_DATA_SUCCESS'),
            detail: this._translateService.instant('MESSAGE.COMMON_SUCCESS_HEADER'),
            life: life
        });
    };
    NotifierService.prototype.showDeleteDataError = function (life) {
        if (life === void 0) { life = 5000; }
        // this._messageService.add({
        //     severity: 'error',
        //     summary : this._translateService.instant('MESSAGE.DELETE_DATA_ERROR'),
        //     detail  : this._translateService.instant('MESSAGE.COMMON_ERROR_HEADER'),
        //     life    : life
        // });
    };
    NotifierService.prototype.showDeleteDataEmptyError = function (life) {
        if (life === void 0) { life = 5000; }
        // this._messageService.add({
        //     severity: 'error',
        //     summary : this._translateService.instant('Bạn phải chọn ít nhất 1 bản ghi để xóa'),
        //     detail  : this._translateService.instant('MESSAGE.COMMON_ERROR_HEADER'),
        //     life    : life
        // });
    };
    NotifierService.prototype.showWarning = function (message, header, life) {
        if (header === void 0) { header = ''; }
        if (life === void 0) { life = 10000; }
        this._messageService.add({
            severity: 'warn',
            summary: header,
            detail: message,
            life: life
        });
    };
    NotifierService.prototype.showError = function (message, header, life) {
        if (header === void 0) { header = ''; }
        if (life === void 0) { life = 10000; }
        this._messageService.add({
            // key: key,
            severity: 'error',
            summary: header,
            detail: message,
            life: life
        });
    };
    NotifierService.prototype.showHttpUnknowError = function (life) {
        if (life === void 0) { life = 10000; }
        this._messageService.add({
            severity: 'error',
            summary: this._translateService.instant('MESSAGE.HTTP_UNKNOW_ERROR_HEADER'),
            detail: this._translateService.instant('MESSAGE.HTTP_UNKNOW_ERROR'),
            life: life
        });
    };
    NotifierService.prototype.showResponseError = function (status, life) {
        if (life === void 0) { life = 10000; }
        // if (status === 404) {
        //     this._messageService.add({
        //         severity: 'error',
        //         summary : this._translateService.instant('MESSAGE.COMMON_ERROR_HEADER'),
        //         detail  : this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'),
        //         life    : life
        //     });
        // } else if (status === 401) {
        //     this._messageService.add({
        //         severity: 'error',
        //         summary : this._translateService.instant('MESSAGE.COMMON_ERROR_HEADER'),
        //         detail  : this._translateService.instant('MESSAGE.UNAUTHORIZED_ERROR'),
        //         life    : life
        //     });
        // } else if (status === 403) {
        //     this._messageService.add({
        //         severity: 'error',
        //         summary : this._translateService.instant('MESSAGE.COMMON_ERROR_HEADER'),
        //         detail  : this._translateService.instant('MESSAGE.ACCESS_DENINED_ERROR'),
        //         life    : life
        //     });
        // } else if (status === 500) {
        //     this._messageService.add({
        //         severity: 'error',
        //         summary : this._translateService.instant('MESSAGE.COMMON_ERROR_HEADER'),
        //         detail  : this._translateService.instant('MESSAGE.INTERNAL_SERVER_ERROR'),
        //         life    : life
        //     });
        // } else if (status === 409) {
        //     this._messageService.add({
        //         severity: 'error',
        //         summary : this._translateService.instant('MESSAGE.COMMON_ERROR_HEADER'),
        //         detail  : this._translateService.instant('MESSAGE.CONFLICT_ERROR'),
        //         life    : life
        //     });
        // }
    };
    NotifierService.prototype.showInsertDataFailed = function (life) {
        if (life === void 0) { life = 10000; }
        this._messageService.add({
            severity: 'error',
            summary: this._translateService.instant('MESSAGE.INSERT_DATA_FAILED'),
            detail: this._translateService.instant('MESSAGE.COMMON_ERROR'),
            life: life
        });
    };
    NotifierService.prototype.showUpdateDataFailed = function (life) {
        if (life === void 0) { life = 10000; }
        this._messageService.add({
            severity: 'error',
            summary: this._translateService.instant('MESSAGE.UPDATE_DATA_FAILED'),
            detail: this._translateService.instant('MESSAGE.COMMON_ERROR'),
            life: life
        });
    };
    NotifierService.prototype.showUpdateDataSingleFailed = function (life) {
        if (life === void 0) { life = 10000; }
        // this._messageService.add({
        //     severity: 'error',
        //     summary : this._translateService.instant('MESSAGE.UPDATE_DATA_FAILED'),
        //     detail  : this._translateService.instant('Mã hoặc tên danh mục đã tồn tại'),
        //     life    : life
        // });
    };
    NotifierService.prototype.showUpdateDataConfigurationFailed = function (life) {
        // this._messageService.add({
        //     severity: 'error',
        //     summary : this._translateService.instant('Mật khẩu không được để trống'),
        if (life === void 0) { life = 10000; }
        //     life: life
        // });
    };
    NotifierService.prototype.showConfirm = function (message, header, icon) {
        var _this = this;
        if (header === void 0) { header = ''; }
        if (icon === void 0) { icon = ''; }
        return new Promise(function (resolve, reject) {
            _this._confirmService.confirm({
                message: message,
                header: header,
                icon: icon,
                acceptLabel: _this._translateService.instant('CONFIRM.ACCEPT_LABEL'),
                rejectLabel: _this._translateService.instant('CONFIRM.REJECT_LABEL'),
                accept: function () {
                    resolve();
                },
                reject: function () {
                    reject();
                }
            });
        });
    };
    NotifierService.prototype.showDeleteConfirm = function (header, icon) {
        var _this = this;
        if (header === void 0) { header = ''; }
        if (icon === void 0) { icon = ''; }
        return new Promise(function (resolve, reject) {
            _this._confirmService.confirm({
                message: _this._translateService.instant('CONFIRM.DEFAULT_DELETE_CONFIRM'),
                header: _this._translateService.instant('CONFIRM.DEFAULT_DELETE_CONFIRM_TITLE'),
                icon: icon,
                acceptLabel: _this._translateService.instant('CONFIRM.ACCEPT_LABEL'),
                rejectLabel: _this._translateService.instant('CONFIRM.REJECT_LABEL'),
                accept: function () {
                    resolve();
                },
                reject: function () {
                    reject();
                }
            });
        });
    };
    NotifierService.prototype.addErrorMessage = function (msgs, content) {
        msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Validation failed' });
    };
    NotifierService.prototype.clearErrorMessage = function (msgs) {
        msgs.length = 0;
    };
    NotifierService.ngInjectableDef = i0.defineInjectable({ factory: function NotifierService_Factory() { return new NotifierService(i0.inject(i1.MessageService), i0.inject(i2.ConfirmationService), i0.inject(i3.TranslateService)); }, token: NotifierService, providedIn: "root" });
    return NotifierService;
}());
export { NotifierService };
