import { Injectable, Injector } from '@angular/core';
import { ModuleConfigService } from './module-config.service';
import { PsBaseService, ResponseResult } from './psbase.service';
import { PsThongBao, PsNotify } from '../models/psthongbao';
import { retry, catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class PsThongBaoService extends PsBaseService<PsThongBao> {
    constructor(http: HttpClient, injector: Injector, config: ModuleConfigService) {
        super(http, 'config.getConfig().', injector);
    }
    sendNotify(myNotify: PsNotify): Promise<ResponseResult> {
        const url = `${environment.apiDomain.notificationEndpoint}/notifications`;
        return this.http
            .post<ResponseResult>(url, myNotify)
            .pipe(retry(3), catchError(err => this.handleError(err, this.injector))).map(rs => rs).toPromise();
    }
    guiThongBaoQuaEmail(thongbao: PsThongBao) {
        const url = `${this.svUrl}/GuiThongBaoQuaEmail`;
        return this.http
            .post<ResponseResult>(url, thongbao)
            .pipe(retry(3), catchError(err => this.handleError(err, this.injector))).map(rs => rs).toPromise();
    }
}
