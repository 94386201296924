var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import * as JWT from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PsAuthenService } from '../auth/authen.service';
import { User } from '../models/user';
import { BaseService } from './base.service';
import { PsCommonService } from './ps-common.service';
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService(http, injector, _oauthService, _commonService) {
        var _this = _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/Users") || this;
        _this._oauthService = _oauthService;
        _this._commonService = _commonService;
        _this.USER_INFO_KEY = 'user_info';
        _this.authenticationEndpoint = environment.apiDomain.authenticationEndpoint + "/users";
        _this.RETRY_COUNT = 0;
        _this.REPLAY_COUNT = 10;
        _this.tokenReceived = new Observable();
        _this._unSubscribeAll = new Subject();
        _this.promises = [];
        _this.isReady = false;
        return _this;
    }
    UserService.prototype.returnPromises = function () {
        while (this.promises.length > 0) {
            var pr = this.promises.pop();
            var accessToken = this._oauthService.getAccessToken();
            var decoded = JWT(accessToken);
            this.getCurrentUser();
        }
        this.isReady = true;
    };
    UserService.prototype.handleError = function (error, injector) {
        // console.error('Có lỗi xảy ra', error);
        if (error.status === 401) {
            var authenService = injector.get(PsAuthenService);
            authenService.logout();
        }
        else {
        }
        return Promise.reject(error);
    };
    UserService.prototype.changePassword = function (item) {
        var apiUrl = this.authenticationEndpoint + "/ChangePassword";
        return this.defaultPost(apiUrl, item);
    };
    UserService.prototype.Create = function (obj) {
        var queryString = this.authenticationEndpoint + "}/Create";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.Update = function (obj) {
        var queryString = this.authenticationEndpoint + "/Update";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.getCaptchaUrl = function () {
        return this.authenticationEndpoint + "}/captcha?" + Date.now() + "&logSession=" + sessionStorage.getItem("log_session_key");
    };
    UserService.prototype.getBasicUserInfo = function () {
        var crrUser = new User();
        var accessToken = this._oauthService.getAccessToken();
        if (accessToken) {
            var claims = JWT(accessToken);
            if (claims) {
                crrUser.displayName = claims.displayname;
                crrUser.email = claims.email;
                crrUser.fullName = claims.firstname.concat(' ', claims.lastname);
                crrUser.issuperuser = claims.issuperuser.toLowerCase() === 'true';
                crrUser.permissions = claims.permissions;
                crrUser.scope = claims.scope;
                crrUser.userId = claims.sub;
                crrUser.userName = claims.username;
                crrUser.avatar = this._commonService.getFileUrl(claims.avatar);
            }
        }
        return crrUser;
    };
    UserService.prototype.getCurrentUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var crrUser, accessToken, claims;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        crrUser = new User();
                        accessToken = this._oauthService.getAccessToken();
                        if (!accessToken) return [3 /*break*/, 4];
                        claims = JWT(accessToken);
                        if (!claims) return [3 /*break*/, 4];
                        crrUser.displayName = claims.displayname;
                        crrUser.email = claims.email;
                        crrUser.fullName = claims.firstname.concat(' ', claims.lastname);
                        crrUser.issuperuser = claims.issuperuser.toLowerCase() === 'true';
                        crrUser.permissions = claims.permissions;
                        crrUser.scope = claims.scope;
                        crrUser.userId = claims.sub;
                        crrUser.userName = claims.username;
                        crrUser.avatar = this._commonService.getFileUrl(claims.avatar);
                        if (!localStorage.getItem(this.USER_INFO_KEY)) return [3 /*break*/, 1];
                        try {
                            return [2 /*return*/, JSON.parse(localStorage.getItem(this.USER_INFO_KEY))];
                        }
                        catch (e) {
                        }
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getCurrent().then(function (rs) {
                            if (rs.status) {
                                crrUser.email = rs.data.email;
                                crrUser.avatar = rs.data.avatar;
                                crrUser.id = rs.data.id;
                                crrUser.idClient = rs.data.idClient;
                                crrUser.name = rs.data.name;
                                crrUser.phone = rs.data.phone;
                                crrUser.idProvince = rs.data.idProvince;
                                crrUser.idDistrict = rs.data.idDistrict;
                                crrUser.idWard = rs.data.idWard;
                                crrUser.address = rs.data.address;
                                crrUser.isDeleted = rs.data.isDeleted;
                                localStorage.setItem(_this.USER_INFO_KEY, JSON.stringify(crrUser));
                            }
                        })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/, JSON.parse(localStorage.getItem(this.USER_INFO_KEY))];
                    case 4: return [2 /*return*/, crrUser];
                }
            });
        });
    };
    UserService.prototype.search = function (term, isActive, pageIndex, pageSize, roleId, isDisabled, isSuperUser) {
        return this._http.get(this.serviceUri + "/managerment?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&term=" + term + "\n    &isActive=" + isActive + "&roleId=" + roleId + "&isDisabled=" + isDisabled + "&isSuperUser=" + isSuperUser).toPromise();
    };
    UserService.prototype.Gets = function (key, offset, limit, orderCol, orderType) {
        if (orderCol === void 0) { orderCol = ''; }
        if (orderType === void 0) { orderType = 0; }
        var queryString = this.serviceUri + "?key=" + key + "&offset=" + offset + "&limit=" + limit + "&orderCol=" + orderCol + "&orderType=" + orderType;
        return this.defaultGet(queryString);
    };
    UserService.prototype.get = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.getCurrent = function () {
        return this.defaultGet(this.serviceUri + "/getCurrent");
    };
    return UserService;
}(BaseService));
export { UserService };
