var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
var RemoteStorage = /** @class */ (function () {
    function RemoteStorage() {
        this._init = false;
        this._connected = false;
        this._requests = {};
        this._count = 0;
        this.OnStorageChanged = new EventEmitter();
    }
    RemoteStorage.prototype.init = function (endpoint) {
        var _this = this;
        var client = this;
        client.Origin = endpoint.substring(0, endpoint.indexOf('/', 8));
        return new Promise(function (resolve) {
            if (_this._init) {
                console.warn('Remote Storage has been inited!');
                return;
            }
            client.RpcFrame = document.createElement('iframe');
            client.RpcFrame.id = client.uuid();
            client.RpcFrame.width = '1px';
            client.RpcFrame.height = '1px';
            client.RpcFrame.style.display = 'none';
            client.RpcFrame.style.position = '-9999px';
            client.RpcFrame.src = endpoint;
            client.RpcFrame.onload = function () {
                client._connected = true;
                client.RpcFrame.contentWindow.postMessage({
                    command: 'connect'
                }, _this.Origin);
                client.Ready = true;
                resolve();
            };
            client._init = true;
            window.addEventListener('message', function (response) {
                if (response.data.event) {
                    // sync
                    if (response.data.payload.key == null && response.data.payload.oldValue == null && response.data.payload.newValue == null) {
                        localStorage.clear();
                        sessionStorage.clear();
                    }
                    else if (response.data.payload.key != null && response.data.payload.newValue == null) {
                        localStorage.removeItem(response.data.payload.key);
                        sessionStorage.removeItem(response.data.payload.key);
                    }
                    else {
                        localStorage.setItem(response.data.payload.key, response.data.payload.newValue);
                        sessionStorage.setItem(response.data.payload.key, response.data.payload.newValue);
                    }
                    client.OnStorageChanged.emit(response);
                }
                else if (response.data.sync) {
                }
                else if (response.data.id) {
                    if (client._requests[response.data.id]) {
                        client._requests[response.data.id](response.data.error, response.data.result);
                    }
                }
            });
            window.document.body.appendChild(client.RpcFrame);
        });
    };
    RemoteStorage.prototype.clear = function () {
        if (!this._connected) {
            console.error('Storage not connected!');
        }
        this.RpcFrame.contentWindow.postMessage({
            command: 'clear'
        }, this.Origin);
    };
    RemoteStorage.prototype.setItem = function (key, value) {
        if (!this._connected) {
            console.error('Storage not connected!');
        }
        this.RpcFrame.contentWindow.postMessage({
            command: 'set',
            payload: {
                key: key,
                value: value
            }
        }, this.Origin);
    };
    RemoteStorage.prototype.getItem = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var client, requestId;
            var _this = this;
            return __generator(this, function (_a) {
                if (!this._connected) {
                    console.error('Storage not connected!');
                }
                client = this;
                requestId = client.RpcFrame.id + ":" + client._count;
                client._count++;
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        client._requests[requestId] = function (err, result) {
                            delete client._requests[requestId];
                            if (err)
                                return reject(new Error(err));
                            resolve(result);
                        };
                        client.RpcFrame.contentWindow.postMessage({
                            command: 'get',
                            id: requestId,
                            payload: {
                                key: key
                            }
                        }, _this.Origin);
                    })];
            });
        });
    };
    RemoteStorage.prototype.syncStorage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this._connected) {
                    console.error('Storage not connected!');
                }
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.getItem('*').then(function (result) {
                            if (result) {
                                result.forEach(function (item) {
                                    localStorage.setItem(item.key, item.value);
                                    sessionStorage.setItem(item.key, item.value);
                                });
                                resolve();
                            }
                        });
                    })];
            });
        });
    };
    RemoteStorage.prototype.uuid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    return RemoteStorage;
}());
export { RemoteStorage };
