var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { ScrollPanel } from 'primeng/primeng';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from './config/oidc-auth.config';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HeartBeatService } from './services/heart-beat.service';
import { environment } from '../environments/environment';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfigurationService } from './lib-shared/services/configuration.service';
import { AuthorizeService } from './lib-shared/services/authorize.service';
import { PsAuthenService } from './lib-shared/auth/authen.service';
import { GlobalService } from './services/global.service';
import { CommonService } from './lib-shared/services/common.service';
import { HtMenuService } from './lib-shared/services/htmenu.service';
import { UserService } from './lib-shared/services/user.service';
var MenuOrientation;
(function (MenuOrientation) {
    MenuOrientation[MenuOrientation["STATIC"] = 0] = "STATIC";
    MenuOrientation[MenuOrientation["OVERLAY"] = 1] = "OVERLAY";
    MenuOrientation[MenuOrientation["SLIM"] = 2] = "SLIM";
    MenuOrientation[MenuOrientation["HORIZONTAL"] = 3] = "HORIZONTAL";
})(MenuOrientation || (MenuOrientation = {}));
var AppComponent = /** @class */ (function () {
    function AppComponent(renderer, translate, _oauthService, _userService, _activatedRoute, _router, _heartBeatService, _commonService, _menuService, _authorizeService, _tnAuthenService, _globalService, _configurationService) {
        var _this = this;
        this.renderer = renderer;
        this.translate = translate;
        this._oauthService = _oauthService;
        this._userService = _userService;
        this._activatedRoute = _activatedRoute;
        this._router = _router;
        this._heartBeatService = _heartBeatService;
        this._commonService = _commonService;
        this._menuService = _menuService;
        this._authorizeService = _authorizeService;
        this._tnAuthenService = _tnAuthenService;
        this._globalService = _globalService;
        this._configurationService = _configurationService;
        this.layoutMode = MenuOrientation.STATIC;
        this.darkMenu = false;
        this.profileMode = 'top';
        this.currentRoute = '';
        this.pageLoaded = false;
        this.showPinnedMenu = false;
        this.isInPinnedMenu = false;
        this.isSearchMenuInputFocus = false;
        this.listAppNav = [];
        this.tokenProcessing = false;
        this.translate.setDefaultLang('vn');
        this.translate.use('vn');
        this.configureWithNewConfigApi();
        this._router.events.subscribe(function (val) {
            if (val instanceof NavigationEnd) {
                var newKey = _this.newGuid();
                sessionStorage.setItem(_this._configurationService.logSessionKey, newKey);
            }
        });
    }
    AppComponent.prototype.ngAfterViewInit = function () {
        // setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    };
    AppComponent.prototype.loadMenu = function () {
        var _this = this;
        this._menuService.getByIdPhanHe(environment.clientDomain.idPhanhe, 0).then(function (rs) {
            if (rs.status) {
                _this.appMenuModel = rs.data;
                sessionStorage.setItem('htMenu' + environment.clientDomain.idPhanhe.toString(), JSON.stringify(rs.data));
            }
        });
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!environment.production) {
            // for dev evironment
            setInterval(function () {
                _this._heartBeatService.sendHeartBeat();
            }, 70000);
        }
        // const cacheVal = sessionStorage.getItem('htMenu' + environment.clientDomain.idPhanhe.toString());
        // if (cacheVal !== undefined && cacheVal !== null) {
        //     this.appMenuModel = JSON.parse(cacheVal);
        // } else {
        // }
        this.listAppNav.push({ icon: 'icon-g-qtc', text: 'Quản trị hệ thống', url: environment.clientDomain.adminDomain });
    };
    AppComponent.prototype.configureWithNewConfigApi = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._oauthService.setStorage(localStorage);
                        this._oauthService.configure(authConfig);
                        this._oauthService.tokenValidationHandler = new JwksValidationHandler();
                        if (!!this._oauthService.hasValidAccessToken()) return [3 /*break*/, 1];
                        // if (!localStorage.getItem('currentUrl')) {
                        //     localStorage.setItem('currentUrl', top.location.href);
                        // }
                        this._oauthService.loadDiscoveryDocumentAndTryLogin().then(function (rs) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!!rs) return [3 /*break*/, 1];
                                        this._oauthService.initImplicitFlow();
                                        return [3 /*break*/, 3];
                                    case 1:
                                        this._userService.returnPromises();
                                        this._oauthService.loadDiscoveryDocument();
                                        return [4 /*yield*/, this._authorizeService.fetchAuthorization()];
                                    case 2:
                                        _a.sent();
                                        this.pageLoaded = true;
                                        this.loadMenu();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 1:
                        this._userService.returnPromises();
                        this._oauthService.loadDiscoveryDocument();
                        return [4 /*yield*/, this._authorizeService.fetchAuthorization()];
                    case 2:
                        _a.sent();
                        this.pageLoaded = true;
                        this.loadMenu();
                        _a.label = 3;
                    case 3:
                        // Optional
                        this._oauthService.setupAutomaticSilentRefresh();
                        return [2 /*return*/];
                }
            });
        });
    };
    // onLayoutClick() {
    //     if (!this.topbarItemClick) {
    //         this.activeTopbarItem = null;
    //         this.topbarMenuActive = false;
    //     }
    //     if (!this.menuClick) {
    //         if (this.isHorizontal() || this.isSlim()) {
    //             this.resetMenu = true;
    //         }
    //         if (this.overlayMenuActive || this.staticMenuMobileActive) {
    //             this.hideOverlayMenu();
    //         }
    //         this.menuHoverActive = false;
    //     }
    //     this.topbarItemClick = false;
    //     this.menuClick = false;
    // }
    AppComponent.prototype.onMenuButtonClick = function (event) {
        this.menuClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;
        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }
        else {
            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            }
            else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }
        }
        event.preventDefault();
    };
    AppComponent.prototype.onMenuClick = function ($event) {
        var _this = this;
        this.menuClick = true;
        this.resetMenu = false;
        if (!this.isHorizontal()) {
            setTimeout(function () { _this.layoutMenuScrollerViewChild.moveBar(); }, 450);
        }
    };
    AppComponent.prototype.onTopbarMenuButtonClick = function (event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        this.hideOverlayMenu();
        event.preventDefault();
    };
    AppComponent.prototype.onTopbarItemClick = function (event, item) {
        this.topbarItemClick = true;
        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        }
        else {
            this.activeTopbarItem = item;
        }
        event.preventDefault();
    };
    AppComponent.prototype.onSwitchModule = function (moduleCode) {
        switch (moduleCode) {
            case 1: {
                this._router.navigate([environment.clientDomain.adminDomain]);
                break;
            }
            default: { // frontend
                this._router.navigate([environment.clientDomain.appDomain]);
                break;
            }
        }
    };
    AppComponent.prototype.onTopbarSubItemClick = function (event) {
        event.preventDefault();
    };
    AppComponent.prototype.onEditInfo = function (event) {
        event.preventDefault();
        // this.profile.showPopup(this._userService.getBasicUserInfo().userId);
    };
    AppComponent.prototype.onChangePassword = function (event) {
        event.preventDefault();
        // this.changePassword.showPopup(this._userService.getBasicUserInfo().userId);
    };
    AppComponent.prototype.onTopbarLogout = function () {
        localStorage.clear();
        sessionStorage.clear();
        this._oauthService.logOut();
        var returnUrl = "?return=" + encodeURIComponent(window.location.href);
        window.location.href = environment.apiDomain.authenticationEndpoint + "/Account/Logout?return=" + returnUrl;
    };
    AppComponent.prototype.hideOverlayMenu = function () {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    };
    AppComponent.prototype.isTablet = function () {
        var width = window.innerWidth;
        return width <= 1024 && width > 640;
    };
    AppComponent.prototype.isDesktop = function () {
        return window.innerWidth > 1024;
    };
    AppComponent.prototype.isMobile = function () {
        return window.innerWidth <= 640;
    };
    AppComponent.prototype.isOverlay = function () {
        return this.layoutMode === MenuOrientation.OVERLAY;
    };
    AppComponent.prototype.isHorizontal = function () {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    };
    AppComponent.prototype.isSlim = function () {
        return this.layoutMode === MenuOrientation.SLIM;
    };
    AppComponent.prototype.changeToStaticMenu = function () {
        this.layoutMode = MenuOrientation.STATIC;
    };
    AppComponent.prototype.changeToOverlayMenu = function () {
        this.layoutMode = MenuOrientation.OVERLAY;
    };
    AppComponent.prototype.changeToHorizontalMenu = function () {
        this.layoutMode = MenuOrientation.HORIZONTAL;
    };
    AppComponent.prototype.changeToSlimMenu = function () {
        this.layoutMode = MenuOrientation.SLIM;
    };
    AppComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._sub.unsubscribe();
    };
    AppComponent.prototype.newGuid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    // search menu
    AppComponent.prototype.onSearchMenu = function (event) {
        this.searchMenuResult = [];
        if (!event || !event.query) {
            return;
        }
        this.searchMenuResult = this.getSearchMenuResult(event.query);
    };
    AppComponent.prototype.getSearchMenuResult = function (keyword) {
        var result = [];
        this.regroupSearchMenuResult(keyword, this.appMenuModel, result);
        return result;
    };
    AppComponent.prototype.regroupSearchMenuResult = function (keyword, listToGet, listToPush) {
        var _this = this;
        listToGet.forEach(function (item) {
            if (item.items && item.items.length) {
                _this.regroupSearchMenuResult(keyword, item.items, listToPush);
            }
            if (item.trangThai === 1
                && (item.label.toUpperCase().indexOf(keyword.toUpperCase()) >= 0)) {
                listToPush.push(item);
            }
        });
    };
    AppComponent.prototype.onMouseOverPinnedMenu = function () {
        this.isInPinnedMenu = true;
    };
    AppComponent.prototype.onMouseLeavePinnedMenu = function () {
        this.isInPinnedMenu = false;
        if (this.isSearchMenuInputFocus) {
            return;
        }
        this.searchMenuInput = '';
        this.showPinnedMenu = false;
    };
    AppComponent.prototype.onSelectMenuSuggestion = function (menuItemObj) {
        this._router.navigateByUrl(menuItemObj.routerLink);
        this.searchMenuInput = '';
        this.showPinnedMenu = false;
    };
    AppComponent.prototype.onBlurSearchMenuInput = function (event) {
        this.isSearchMenuInputFocus = false;
        if (this.isInPinnedMenu) {
            return;
        }
        this.searchMenuInput = '';
        this.showPinnedMenu = false;
    };
    AppComponent.prototype.onFocusSearchMenuInput = function (event) {
        this.isSearchMenuInputFocus = true;
        this.showPinnedMenu = true;
    };
    return AppComponent;
}());
export { AppComponent };
