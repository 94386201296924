import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HeartBeatService = /** @class */ (function () {
    function HeartBeatService(_http) {
        this._http = _http;
        this.listFailedHeartBeat = [];
    }
    HeartBeatService.prototype.sendHeartBeat = function () {
        var _loop_1 = function (key) {
            if (this_1.listFailedHeartBeat.findIndex(function (x) { return x === key; }) > -1) {
                return "continue";
            }
            // const infoUrl = `${environment.apiDomain[key]}/info`;
            // this._http.get(infoUrl).toPromise()
            //     .then(rs => {
            //         this.listFailedHeartBeat.splice(this.listFailedHeartBeat.findIndex(x => x === key), 1);
            //     })
            //     .catch(err => {
            //         this.listFailedHeartBeat.push(key);
            //     });
        };
        var this_1 = this;
        for (var key in environment.apiDomain) {
            _loop_1(key);
        }
    };
    HeartBeatService.ngInjectableDef = i0.defineInjectable({ factory: function HeartBeatService_Factory() { return new HeartBeatService(i0.inject(i1.HttpClient)); }, token: HeartBeatService, providedIn: "root" });
    return HeartBeatService;
}());
export { HeartBeatService };
